@import "../variables";

@lg-select-height: 40px;

body {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 12px;
    border-color: @border-color-base;
    background-color: white;
    height: 32px;
    padding: 2px 7px;

    .ant-select-selection-search-input {
      &::placeholder {
        color: @text-color;
      }
    }
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 30px;
  }

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: @lg-select-height;
  }

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
    height: @lg-select-height;
  }

  .ant-select-lg {
    height: @lg-select-height;
  }

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
    line-height: @lg-select-height - 2;
  }
}
