@import "../variables";

.ant-row {
  &.ant-form-item {
    margin-bottom: @form-item-margin-bottom;

    &.compact {
      margin-bottom: 3px;
    }
  }
}

.ant-form-item-explain, .ant-form-item-extra {
  margin-top: 0;
}

body {
  .ant-form-large .ant-form-item-label > label {
    height: auto;
  }

  .ant-form {
    font-feature-settings: 'pnum' on,'lnum' on;
  }
}
