@import "variables";

body {
  font-family: @font-family;
  font-feature-settings: @font-feature-settings-base;
  background-color: @body-background;

  a {
    text-decoration: underline;
    color: @text-color;
    &:hover {
      text-decoration: underline;
    }
  }

  .ant-notification {
    z-index: 1070;
  }

  .w-100 {
    width: 100%;
  }
}

h1, h2, h3, h4, h5, h6 {
  color: @text-color;
}
