@import '../variables';

@input-border-radius: 12px;
@input-padding-horizontal-base: 8px;
@input-padding-vertical-base: 5px;
@input-placeholder-color: rgba(0, 0, 0, 0.25);

body {
  .ant-input {
    width: 100%;
    border-radius: @input-border-radius;
    border-color: @border-color-base;
    background-color: white;
    min-height: 28px;
    padding: @input-padding-vertical-base @input-padding-horizontal-base;

    &::placeholder {
      color: rgba(0, 0, 0, 0.25);
    }
  }

  textarea.ant-input {
    padding: @input-padding-vertical-base @input-padding-horizontal-base;
    border-bottom-right-radius: 4px;
  }

  .ant-input-number {
    // width: 100%;
    border-radius: @input-border-radius;
  }

  .ant-picker {
    border-radius: @input-border-radius;
  }

  .ant-input-number-input {
    border-radius: @input-border-radius;
  }

  .ant-input-number-handler-wrap {
    border-radius: 0 @input-border-radius @input-border-radius 0;
  }

  .ant-input-affix-wrapper {
    border-radius: @input-border-radius;
    padding: 0 7px;

    &.ant-input-affix-wrapper-lg {
      padding: 3.4px 11px;

      .ant-input-lg {
        height: 32px;
      }
    }
  }

  .ant-input-password input {
    border-radius: 0;
  }

  .ant-input-lg {
    height: 40px;
  }

  .react-tel-input .form-control {
    &[disabled] {
      background: #f5f5f5;
    }
  }

  .react-tel-input .flag-dropdown {
    border-radius: @input-border-radius 0 0 @input-border-radius;
  }

  .react-tel-input .selected-flag {
    border-radius: @input-border-radius 0 0 @input-border-radius;
  }
}

