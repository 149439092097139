@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-Medium.eot');
  src: local('Raleway Medium'), local('Raleway-Medium'), url('./fonts/Raleway-Medium.eot?#iefix') format('embedded-opentype'), url('./fonts/Raleway-Medium.woff') format('woff'), url('./fonts/Raleway-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-Regular.eot');
  src: local('Raleway'), local('Raleway-Regular'), url('./fonts/Raleway-Regular.eot?#iefix') format('embedded-opentype'), url('./fonts/Raleway-Regular.woff') format('woff'), url('./fonts/Raleway-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-MediumItalic.eot');
  src: local('Raleway Medium Italic'), local('Raleway-MediumItalic'), url('./fonts/Raleway-MediumItalic.eot?#iefix') format('embedded-opentype'), url('./fonts/Raleway-MediumItalic.woff') format('woff'), url('./fonts/Raleway-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-BoldItalic.eot');
  src: local('Raleway Bold Italic'), local('Raleway-BoldItalic'), url('./fonts/Raleway-BoldItalic.eot?#iefix') format('embedded-opentype'), url('./fonts/Raleway-BoldItalic.woff') format('woff'), url('./fonts/Raleway-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-SemiBoldItalic.eot');
  src: local('Raleway SemiBold Italic'), local('Raleway-SemiBoldItalic'), url('./fonts/Raleway-SemiBoldItalic.eot?#iefix') format('embedded-opentype'), url('./fonts/Raleway-SemiBoldItalic.woff') format('woff'), url('./fonts/Raleway-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-SemiBold.eot');
  src: local('Raleway SemiBold'), local('Raleway-SemiBold'), url('./fonts/Raleway-SemiBold.eot?#iefix') format('embedded-opentype'), url('./fonts/Raleway-SemiBold.woff') format('woff'), url('./fonts/Raleway-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-Italic.eot');
  src: local('Raleway Italic'), local('Raleway-Italic'), url('./fonts/Raleway-Italic.eot?#iefix') format('embedded-opentype'), url('./fonts/Raleway-Italic.woff') format('woff'), url('./fonts/Raleway-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-Bold.eot');
  src: local('Raleway Bold'), local('Raleway-Bold'), url('./fonts/Raleway-Bold.eot?#iefix') format('embedded-opentype'), url('./fonts/Raleway-Bold.woff') format('woff'), url('./fonts/Raleway-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body .ant-menu.ant-menu-inline-collapsed {
  padding: 16px;
}
body .ant-menu-root.ant-menu-vertical,
body .ant-menu-root.ant-menu-vertical-left,
body .ant-menu-root.ant-menu-vertical-right,
body .ant-menu-root.ant-menu-inline {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
body .ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
body .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
body .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
body .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0;
  margin-bottom: 8px;
  text-align: center;
}
body .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
body .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected div {
  background-color: #ECF2F7;
}
body .ant-menu-vertical > .ant-menu-item,
body .ant-menu-vertical-left > .ant-menu-item,
body .ant-menu-vertical-right > .ant-menu-item,
body .ant-menu-inline > .ant-menu-item,
body .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
body .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
body .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
body .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 50px;
  line-height: 48px;
  min-width: 48px;
}
body .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
body .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon,
body .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
body .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
body .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon,
body .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
body .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
body .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  line-height: 44px;
}
body .ant-menu > .ant-menu-item-divider {
  background-color: #d9d9d9;
  margin: 24px 0;
}
body .ant-menu-inline-collapsed-tooltip {
  display: none;
}
body .ant-popover.menu-popover {
  /* 85% text color in rgba */
}
body .ant-popover.menu-popover .ant-popover-arrow-content,
body .ant-popover.menu-popover .ant-popover-inner-content {
  color: white;
  background-color: rgba(60, 63, 74, 0.85);
}
body .ant-popover-arrow {
  width: 8px;
  height: 10px;
}
body .ant-popover-placement-right .ant-popover-arrow,
body .ant-popover-placement-rightTop .ant-popover-arrow,
body .ant-popover-placement-rightBottom .ant-popover-arrow {
  left: 2px;
}
body .ant-layout-sider-zero-width-trigger {
  top: 0;
  margin-right: -16px;
  color: rgba(0, 0, 0, 0.85);
}
body .ant-menu-title-content {
  vertical-align: top;
}
body .ant-menu-inline,
body .ant-menu-vertical,
body .ant-menu-vertical-left {
  padding: 16px;
}
body .ant-menu-inline .ant-menu-item,
body .ant-menu-vertical .ant-menu-item,
body .ant-menu-vertical-left .ant-menu-item,
body .ant-menu-vertical-right .ant-menu-item,
body .ant-menu-inline .ant-menu-submenu-title,
body .ant-menu-vertical .ant-menu-submenu-title,
body .ant-menu-vertical-left .ant-menu-submenu-title,
body .ant-menu-vertical-right .ant-menu-submenu-title {
  padding: 0;
}
@media (max-width: 1200px) {
  body .ant-menu-submenu-popup .ant-menu-item {
    height: 42px;
    line-height: 40px;
  }
  body .ant-menu-submenu-popup svg {
    height: 16px;
    width: 16px;
    margin: 0 12px;
  }
}
.ant-row.ant-form-item {
  margin-bottom: 24px;
}
.ant-row.ant-form-item.compact {
  margin-bottom: 3px;
}
.ant-form-item-explain,
.ant-form-item-extra {
  margin-top: 0;
}
body .ant-form-large .ant-form-item-label > label {
  height: auto;
}
body .ant-form {
  font-feature-settings: 'pnum' on, 'lnum' on;
}
body .ant-input {
  width: 100%;
  border-radius: 12px;
  border-color: #d9d9d9;
  background-color: white;
  min-height: 28px;
  padding: 5px 8px;
}
body .ant-input::placeholder {
  color: rgba(0, 0, 0, 0.25);
}
body textarea.ant-input {
  padding: 5px 8px;
  border-bottom-right-radius: 4px;
}
body .ant-input-number {
  border-radius: 12px;
}
body .ant-picker {
  border-radius: 12px;
}
body .ant-input-number-input {
  border-radius: 12px;
}
body .ant-input-number-handler-wrap {
  border-radius: 0 12px 12px 0;
}
body .ant-input-affix-wrapper {
  border-radius: 12px;
  padding: 0 7px;
}
body .ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
  padding: 3.4px 11px;
}
body .ant-input-affix-wrapper.ant-input-affix-wrapper-lg .ant-input-lg {
  height: 32px;
}
body .ant-input-password input {
  border-radius: 0;
}
body .ant-input-lg {
  height: 40px;
}
body .react-tel-input .form-control[disabled] {
  background: #f5f5f5;
}
body .react-tel-input .flag-dropdown {
  border-radius: 12px 0 0 12px;
}
body .react-tel-input .selected-flag {
  border-radius: 12px 0 0 12px;
}
body .ant-modal-header {
  border-radius: 24px 24px 0 0;
}
body .ant-modal-content {
  border-radius: 24px;
}
body .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 12px;
  border-color: #d9d9d9;
  background-color: white;
  height: 32px;
  padding: 2px 7px;
}
body .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::placeholder {
  color: rgba(0, 0, 0, 0.85);
}
body .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 30px;
}
body .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}
body .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 40px;
}
body .ant-select-lg {
  height: 40px;
}
body .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
body .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
body .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px;
}
body .ant-btn-primary[disabled],
body .ant-btn-primary[disabled]:hover,
body .ant-btn-primary[disabled]:focus,
body .ant-btn-primary[disabled]:active {
  color: #94979B;
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
body .ant-btn {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}
body .ant-btn.ant-btn-primary {
  padding: 0 16px;
}
body .ant-btn.ant-btn-lg {
  height: 40px;
}
.ant-picker.ant-picker-range {
  border-radius: 12px;
}
.ant-picker.calendar-date-selector.ant-picker-range {
  width: 100%;
  padding: 8px 12px;
  border-radius: 12px;
}
.ant-picker.calendar-date-selector .ant-picker-footer {
  justify-content: right;
  align-items: center;
  display: flex;
  height: 36px;
}
body .ant-picker-cell.ant-picker-cell-week,
body th[aria-label='empty cell'] {
  display: none;
}
.ant-table table {
  border: 2px solid #fff;
  background-color: #fff;
  font-feature-settings: 'tnum';
}
.ant-table table .ant-table-thead > tr > th {
  background-color: #fafafa;
  border-bottom: 0;
  padding: 2px 14px;
}
.ant-table table .ant-table-tbody > tr {
  background-color: white;
}
.ant-table table .ant-table-tbody > tr > td {
  border-color: #d9d9d9;
  padding: 8px 16px;
}
.ant-table table .ant-table-tbody > tr:last-child {
  border-radius: 8px;
}
.ant-table table .ant-table-tbody > tr:last-child > td:first-child,
.ant-table table .ant-table-tbody > tr:last-child > th:first-child {
  border-bottom-left-radius: 8px;
}
.ant-table table .ant-table-tbody > tr:last-child > td:last-child,
.ant-table table .ant-table-tbody > tr:last-child > th:last-child {
  border-bottom-right-radius: 8px;
}
.ant-table table > thead > tr:first-child th:first-child {
  border-top-left-radius: 8px;
}
.ant-table table > thead > tr:first-child th:last-child {
  border-top-right-radius: 8px;
}
.ant-table-body table {
  border-radius: 0 0 8px 8px;
}
.ant-dropdown-menu {
  padding: 4px;
  overflow: hidden;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  border-radius: 8px;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-feature-settings: 'tnum';
  background-color: #fff;
}
body a {
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.85);
}
body a:hover {
  text-decoration: underline;
}
body .ant-notification {
  z-index: 1070;
}
body .w-100 {
  width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgba(0, 0, 0, 0.85);
}
