@import '../variables';

.ant-picker.ant-picker-range {
  border-radius: 12px;
}

.ant-picker.calendar-date-selector {
  &.ant-picker-range {
    width: 100%;
    padding: 8px 12px;
    border-radius: 12px;
  }

  .ant-picker-footer {
    justify-content: right;
    align-items: center;
    display: flex;
    height: 36px;
  }
}

body {
  .ant-picker-cell.ant-picker-cell-week,
  th[aria-label='empty cell'] {
    display: none;
  }
}

