@import "variables";

@table-selection-column-width: 58px;
@table-border-radius-base: 8px;

.ant-table table {
  border: 2px solid @body-background;
  background-color: @body-background;
  font-feature-settings: @font-feature-settings-base;

  .ant-table-thead > tr > th {
    background-color: @table-header-bg;
    border-bottom: 0;
    padding: 2px 14px;
  }

  .ant-table-tbody {
    > tr {
      background-color: white;

      > td {
        border-color: @border-color-base;
        padding: 8px 16px;
      }

      &:last-child {
        border-radius: 8px;

        > td:first-child,
        > th:first-child {
          border-bottom-left-radius: 8px;
        }

        > td:last-child,
        > th:last-child {
          border-bottom-right-radius: 8px;
        }
      }
    }
  }

  > thead > tr:first-child th:first-child {
    border-top-left-radius: 8px;
  }

  > thead > tr:first-child th:last-child {
    border-top-right-radius: 8px;
  }
}

.ant-table-body {
  table {
    border-radius: 0 0 @table-border-radius-base @table-border-radius-base;
  }
}
